import React, { useMemo } from 'react';
import classnames from 'classnames';
import { useQuery } from 'utils/react-query';
import Typography from 'components/uiLibrary/Typography';
import queries from 'containers/Globals/queries';
import { useTranslation } from 'src/i18n';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { TP } from 'constants/index';

import classes from './PaywallContent.module.scss';

const PaywallContent = ({ isLoggedIn }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { data } = useQuery(queries.getHomeNumbers());
  const { isMobile } = useDeviceTypeLayouts();

  const staticInfoKeys = {
    performances: `${TP}.m_PERF`,
    companies: `${TP}.m_OPCOS`,
    festivals: `${TP}.m_FESTIVALS`,
    artists: `${TP}.m_ARTISTS`,
    managers: `${TP}.m_MANAGERS`,
  };

  const staticInfo = useMemo(
    () =>
      Object.entries(staticInfoKeys).map(([key, info]) => ({
        number: data?.data[key]?.total,
        info,
      })),
    [data],
  );

  return (
    <div className={classes.paywallContent}>
      <div className={classes.paywallContent__title}>
        <Typography size={isMobile ? 16 : 20} weight="bold">
          {isLoggedIn ? t(`${TP}.UPGRADE_MESSAGE_HARDWALL`) : t(`${TP}.VIEW_MORE_MESSAGE`)}
        </Typography>
      </div>
      <div className={classes.paywallContent__desc}>
        <Typography variant="p" size={isMobile ? 12 : 14}>
          {t(`${TP}.SINCE_1996_MESSAGE`)}
        </Typography>
        {!isMobile && (
          <Typography variant="p" size={isMobile ? 11 : 14}>
            {t(`${TP}.NOW_LISTING_MESSAGE`, {
              performances: data?.data?.performances?.total || 0,
              artists: data?.data?.artists?.total || 0,
              venues: data?.data?.venues?.total || 0,
            })}
          </Typography>
        )}
        {!isMobile && (
          <Typography variant="p" size={isMobile ? 11 : 14}>
            {t(`${TP}.OPERABASE_TRUST_MESSAGE`)}
          </Typography>
        )}
      </div>
      <div className={classes.paywallContent__stats}>
        {data && (
          <div className={classes.paywallContent__stats__inner}>
            {staticInfo.map(item => (
              <Typography
                size={isMobile ? 12 : 14}
                className={classes.paywallContent__stats__inner_statItem}
                key={item.info}
              >
                <Typography
                  size={isMobile ? 12 : 14}
                  weight="medium"
                  className={classes.paywallContent__stats__inner_statItem_num}
                >
                  {' '}
                  {item.number?.toLocaleString()}{' '}
                </Typography>
                <span className={classnames(classes.paywallContent__stats__inner_statInfo)}>{t(item.info)}</span>
              </Typography>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default PaywallContent;
